import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { usercontext } from "../../usercontext";
import Cookies from "js-cookie";
import "./Guesttermsreview.css";
import Navbar from "../Navbar/Navbar";
import Modal from "../Modal/Modal.jsx";
import { frontend_host } from "../../config";
import { FaArrowCircleRight, FaUserCircle, FaRegUserCircle } from 'react-icons/fa';
import { Grid } from '@mui/material'
import ReactModal from "react-modal";
import { Viewer, Worker } from "@react-pdf-viewer/core"; // PDF Viewer


export const Guesttermsreview = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { curruser } = useContext(usercontext);
  const [showResources, setShowResources] = useState(false);
  const [error, setError] = useState(null);
  const [res, setRes] = useState(null);
  const [termsValue, setTermsValue] = useState({});
  const [statuses, setStatuses] = useState({});
  const [resources, setResources] = useState([]);
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const { connection, connectionType } = location.state || {};
  const [conndetails, setconndetails] = useState([]);
  const [resourcesData, setResourcesData] = useState({
    share: [],
    transfer: [],
    confer: [],
    collateral: []
  });
  const [isReactModalOpen, setIsReactModalOpen] = useState(false);
  const [permissionsData, setPermissionsData] = useState([]);
  const [terms, setTerms] = useState([]);
  const [globalTemplates, setGlobalTemplates] = useState([]);
  const [modalMessage, setModalMessage] = useState({ message: "", type: "" });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpens, setIsModalOpens] = useState(false);
  const [isModalOpenClose, setIsModalOpenClose] = useState(false);
  const [connectionDetails, setConnectionDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [revokeState, setRevokeState] = useState(true);
  const [closeState, setCloseState] = useState(true);

  const [statuses2, setStatuses2] = useState({});
  const [activeTab, setActiveTab] = useState("guest");
  const [downloadedResources, setDownloadedResources] = useState({}); // Keeps track of downloaded resources by ID
  const [pdfUrl, setPdfUrl] = useState(null);
  const [showOpenPopup, setShowOpenPopup] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [selectedRowData1, setSelectedRowData1] = useState(null);
  const [pdfData, setPdfData] = useState(null)
  const [resourceModal, setResourceModal] = useState(false);
  const [trackerData, setTrackerData] = useState({});
  const [trackerDataReverse, setTrackerDataReverse] = useState({});


  //   const [revokeMessage, setRevokeMessage] = useState(""); // To store the response message
  // const [isRevokeModalOpen, setRevokeModalOpen] = useState(false);

  // const RevokeMessageModal = ({ message, onClose }) => (
  //     <div className="modal">
  //         <div className="modal-content">
  //             <h2>Revoke Status</h2>
  //             <p>{message}</p>
  //             <button onClick={onClose}>Close</button>
  //         </div>
  //     </div>
  // );

  console.log("resourcesDatass", resourcesData)

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  console.log(connection, connectionType);
  const onRevokeButtonClick = async (connection_id) => {
    setRevokeState(false);
    const message = await handleRevoke(connection_id);
    setIsModalOpen(false);
    setModalMessage({ message: message, type: "info" });
    setIsModalOpen(true);
  };

  const onCloseButtonClick = async (connection_id) => {
    setCloseState(false);
    const message = await handleCloseConnection(connection_id);
    setIsModalOpenClose(false);
    setModalMessage({ message: message, type: "info" });
    setIsModalOpenClose(true);
  };

  useEffect(() => {
    if (!curruser) {
      navigate("/");
      return;
    }

    const fetchGlobalTemplates = () => {
      const token = Cookies.get("authToken");
      fetch("host/get-template-or-templates/".replace(/host/, frontend_host), {
        method: "GET",
        headers: {
          Authorization: `Basic ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log("Fetched Templates:", data); // Log the fetched data
          setGlobalTemplates(data.data); // Store fetched templates
          // console.log("global data", data.data);
        })
        .catch((error) => {
          console.error("Error fetching templates:", error);
          setError("Failed to fetch templates");
        });
    };

    //fetch terms from the api
    const fetchObligations = async () => {
      // console.log("Inside fetch terms");
      try {
        const token = Cookies.get("authToken");
        const connectionTypeName = connection.connection_name
          .split("-")
          .shift()
          .trim();
        let apiUrl = `${frontend_host}/get-terms-by-conntype/?connection_type_name=${connectionTypeName}&host_user_username=${connection.host_user.username}&host_locker_name=${connection.host_locker.name}`;
        //   console.log("Final API URL:", apiUrl);

        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch terms");
        }

        const data = await response.json();

        if (data.success) {
          setTerms(data.data.obligations); // Update to set data.data instead of data
          // console.log("Terms Response Data:", data.data.obligations);
        } else {
          setError(data.error || "No terms found");
        }
      } catch (err) {
        setError(err.message);
      }
    };

    const fetchTerms = async () => {
      try {
        const token = Cookies.get("authToken");
        const response = await fetch(
          `host/show_terms/?username=${connection.guest_user.username}&locker_name=${connection.guest_locker.name}&connection_name=${connection.connection_name}`.replace(
            /host/,
            frontend_host
          ),
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Basic ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch terms");
        }
        const data = await response.json();
        if (data.success) {
          setRes(data.terms);
          console.log("res", data.terms);
          setResources(data.terms.resources || []);

          // Fetch permissions data if canShareMoreData is true
          if (data.terms.permissions.canShareMoreData) {
            await fetchPermissionsData();
          }
        } else {
          setError(data.error || "No terms found");
        }
      } catch (err) {
        setError(err.message);
      }
    };
    const fetchPermissionsData = async () => {
      try {
        const token = Cookies.get("authToken");
        const connectionId = connection.connection_id; // Assume you have a connection ID
        const response = await fetch(
          `host/get-extra-data?connection_id=${connectionId}`.replace(
            /host/,
            frontend_host
          ),
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Basic ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch permissions data");
        }
        const data = await response.json();
        if (data.success) {
          // Create an array from the shared_more_data_terms object
          const sharedData = Object.entries(data.shared_more_data_terms).map(
            ([key, value], index) => ({
              sno: index + 1,
              labelName: key,
              dataElement: value.enter_value,
              purpose: value.purpose,
              share: value.typeOfShare,
              status: value.status,
            })
          );
          setPermissionsData(sharedData);
          console.log(sharedData, "sharedData");
          const initialStatuses2 = {}
          console.log(data.shared_more_data_terms);
          for (const [key, value] of Object.entries(
            data.shared_more_data_terms
          )) {
            initialStatuses2[key] = value.enter_value.endsWith("T")
              ? "approved"
              : value.enter_value.endsWith("R")
                ? "rejected"
                : "";
          }
          setStatuses2(initialStatuses2);
          console.log("statuses initial", initialStatuses2);
        } else {
          setError(data.error || "No permissions data found");
        }
      } catch (err) {
        setError(err.message);
      }
    };

    const fetchConnectionDetails = async () => {
      const connectionTypeName = connection?.connection_name?.split("-").shift().trim()

      console.log("guestTerms get-connection-details", connection);
      console.log(connectionTypeName);
      try {
        const token = Cookies.get("authToken");
        const response = await fetch(
          `host/get-connection-details?connection_type_name=${connectionTypeName}&host_locker_name=${connection.host_locker.name}&host_user_username=${connection.host_user.username}&guest_locker_name=${connection.guest_locker.name}&guest_user_username=${connection.guest_user.username}`.replace(
            /host/,
            frontend_host
          ),
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Basic ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch connection details");
        }
        const data = await response.json();
        if (data.connections) {
          console.log("data conn", data);
          setTermsValue(data.connections.terms_value || {});
          setconndetails(data.connections);
          setConnectionDetails(data.connections);

          console.log("terms_value:", data.connections.terms_value);
          // Check if `terms_value` exists
          console.log("terms_value_reverse:", data.connections.terms_value_reverse);
          if (data.connections.terms_value) {
            const initialStatuses = {};

            for (const [key, value] of Object.entries(
              data.connections.terms_value
            )) {
              if (key !== "canShareMoreData") {
                initialStatuses[key] = value.endsWith("T")
                  ? "approved"
                  : value.endsWith("R")
                    ? "rejected"
                    : "";
              }
            }
            console.log("inside here");
            console.log("initial statuses", initialStatuses);

            setStatuses(initialStatuses);
          } else {
            console.log("No terms_value found");
          }
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTerms();
    fetchConnectionDetails();
    fetchGlobalTemplates();
    fetchObligations();
  }, [curruser, connection, connectionType, navigate]);

  useEffect(() => {
    if (connectionDetails) {
      const { revoke_guest, revoke_host } = connectionDetails;
      //   console.log(revoke_host, revoke_guest);
      if (revoke_guest === true || revoke_host === true) {
        setModalMessage({
          message:
            "The guest has revoked the connection, click Revoke to revoke the connection",
          type: "info",
        });
        setIsModalOpen(true);
      }
    }
  }, [connectionDetails]);

  useEffect(() => {
    if (connectionDetails) {
      const { close_guest, close_host } = connectionDetails;
      //   console.log(revoke_host, revoke_guest);
      if (close_guest === false && close_host === true) {
        setModalMessage({
          message:
            "You have closed the connection, but the guest is yet to approve your close connection.",
          type: "info",
        });
        setIsModalOpenClose(true);
      }
    }
  }, [connectionDetails]);


  useEffect(() => {
    if (connectionDetails) {
      const { close_host } = connectionDetails;
      //   console.log(revoke_host, revoke_guest);
      if (close_host === true) {
        setModalMessage({
          message:
            "You closed  the connection waiting for guest to close",
          type: "info",
        });
        setIsModalOpens(true);
      }
    }
  }, [connectionDetails]);

  useEffect(() => {
    if (connectionDetails) {
      const { close_guest, close_host } = connectionDetails;
      //   console.log(revoke_host, revoke_guest);
      if (close_host === false && close_guest === true) {
        setModalMessage({
          message:
            "The guest has closed the connection, click Close connection to close the connection",
          type: "info",
        });
        setIsModalOpenClose(true);
      }
    }
  }, [connectionDetails]);

  useEffect(() => {
    if (connectionDetails) {
      fetchTrackerData(connectionDetails)
      fetchTrackerDataReverse(connectionDetails);
    }
  }, [connectionDetails]);

  const fetchTrackerData = async (connection) => {
    try {
      const token = Cookies.get("authToken");
      const params = new URLSearchParams({
        connection_name: connection.connection_name,
        host_locker_name: connection.host_locker.name,
        guest_locker_name: connection.guest_locker.name,
        host_user_username: connection.host_user.username,
        guest_user_username: connection.guest_user.username,
      });
      const response = await fetch(
        `host/get-terms-status/?${params}`.replace(/host/, frontend_host),
        {
          method: "GET",
          headers: {
            Authorization: `Basic ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch tracker data");
      }
      const data = await response.json();
      if (data.success) {
        console.log("view locker", data);
        setTrackerData((prevState) => ({
          ...prevState,
          [connection.connection_id]: {
            count_T: data.count_T,
            count_F: data.count_F,
            count_R: data.count_R,
            filled: data.filled,
            empty: data.empty,
          },
        }));
      } else {
        setError(data.message || "Failed to fetch tracker data");
      }
    } catch (error) {
      console.error("Error fetching tracker data:", error);
      setError("An error occurred while fetching tracker data");
    }
  };
  const fetchTrackerDataReverse = async (connection) => {
    try {
      const token = Cookies.get("authToken");
      const params = new URLSearchParams({
        connection_name: connection.connection_name,
        host_locker_name: connection.host_locker.name,
        guest_locker_name: connection.guest_locker.name,
        host_user_username: connection.host_user.username,
        guest_user_username: connection.guest_user.username,
      });
      const response = await fetch(
        `host/get-terms-status-reverse/?${params}`.replace(/host/, frontend_host),
        {
          method: "GET",
          headers: {
            Authorization: `Basic ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch tracker data");
      }
      const data = await response.json();
      if (data.success) {
        console.log("view locker", data);
        setTrackerDataReverse((prevState) => ({
          ...prevState,
          [connection.connection_id]: {
            count_T: data.count_T,
            count_F: data.count_F,
            count_R: data.count_R,
            filled: data.filled,
            empty: data.empty,
          },
        }));
      } else {
        setError(data.message || "Failed to fetch tracker data");
      }
    } catch (error) {
      console.error("Error fetching tracker data:", error);
      setError("An error occurred while fetching tracker data");
    }
  };

  const getStatusColor = (tracker) => {
    const totalObligations =
      tracker.count_T + tracker.count_F + tracker.count_R;
    if (tracker.count_T === totalObligations && tracker.count_R === 0) {
      return "green";
    } else if (tracker.filled === 0 || tracker.count_R === totalObligations) {
      return "red";
    } else {
      return "orange";
    }
  };

  console.log("trackerData", trackerData)
  console.log("trackerDataReverse", trackerDataReverse)

  const getStatusColorReverse = (trackerReverse) => {
    const totalObligations =
      trackerReverse.count_T + trackerReverse.count_F + trackerReverse.count_R;
    if (trackerReverse.count_T === totalObligations && trackerReverse.count_R === 0) {
      return "green";
    } else if (trackerReverse.filled === 0 || trackerReverse.count_R === totalObligations) {
      return "red";
    } else {
      return "orange";
    }
  };

  const calculateRatio = (tracker) => {
    const totalObligations =
      tracker.count_T + tracker.count_F + tracker.count_R;
    return totalObligations > 0
      ? `${tracker.filled}/${totalObligations}`
      : "0/0";
  };

  const calculateRatioReverse = (trackerReverse) => {
    const totalObligations =
      trackerReverse.count_T + trackerReverse.count_F + trackerReverse.count_R;
    return totalObligations > 0
      ? `${trackerReverse.filled}/${totalObligations}`
      : "0/0";
  };

  // const handleStatusChange = (index, status, value, type, isFile) => {
  //     if (value !== "") {
  //         setStatuses(prevStatuses => {
  //             const newStatuses = {
  //                 ...prevStatuses,
  //                 [index]: status
  //             };

  //             setResourcesData(prevData => {
  //                 // Create a new copy for both arrays to avoid unintended mutations
  //                 const updatedResources = {
  //                     transfer: [...prevData.transfer],
  //                     share: [...prevData.share]
  //                 };

  //                 // Check the type and isFile conditions separately
  //                 if (isFile) {
  //                     if (type === 'transfer') {
  //                         if (status === 'approved' && !updatedResources.transfer.includes(value)) {
  //                             updatedResources.transfer.push(value);
  //                         } else if (status === 'rejected') {
  //                             updatedResources.transfer = updatedResources.transfer.filter(item => item !== value);
  //                         }
  //                     } else if (type === 'share') {
  //                         if (status === 'approved' && !updatedResources.share.includes(value)) {
  //                             updatedResources.share.push(value);
  //                         } else if (status === 'rejected') {
  //                             updatedResources.share = updatedResources.share.filter(item => item !== value);
  //                         }
  //                     }
  //                 }

  //                 return updatedResources; // Ensure returning the correct state
  //             });

  //             return newStatuses;
  //         });
  //     } else {
  //         alert("Value required in Enter Value field to either Approve or Reject");
  //     }
  // };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsModalOpens(false);
    setModalMessage({ message: "", type: "" });
    navigate(`/view-locker?param=${Date.now()}`, {
      state: { locker: conndetails.host_locker },
    });
  };

  const handleCloseModalClose = () => {
    setIsModalOpenClose(false);
    setModalMessage({ message: "", type: "" });
    navigate(`/view-locker?param=${Date.now()}`, {
      state: { locker: conndetails.host_locker },
    });
  };
  const openPopup = (rowData) => {
    const labelName = rowData?.labelName
    const extractedValue = termsValue[labelName]?.split(";")[0].split("|")[1]; // Extract the required value
    handleClicks(extractedValue); // Pass the extracted value to handleClicks
    setSelectedRowData(rowData);
    setShowOpenPopup(true);
  };

  const openPopup1 = (rowData) => {
    console.log("selectedRowDatas1", rowData?.dataElement?.split("|")[1])
    // const labelName = rowData?.labelName
    const extractedValue = rowData?.dataElement?.split("|")[1]; // Extract the required value
    handleClicks(extractedValue); // Pass the extracted value to handleClicks
    setSelectedRowData1(rowData);
    setShowOpenPopup(true);
  };

  const handleCloseResourceModal = () => {
    setResourceModal(false);
    setSelectedRowData(null)
    setModalMessage({ message: "", type: "" });
  };
  const closeOpenPopup = () => {
    setShowOpenPopup(false);
    setSelectedRowData(null);
    setSelectedRowData1(null);
    setPdfData(null);
  };

  const handleClick = async (xnode_id_with_pages) => {
    const xnode_id = xnode_id_with_pages?.split(',')[0];
    const pages = xnode_id_with_pages?.split(',')[1];
    const from_page = parseInt(pages?.split(':')[0].split("(")[1], 10);
    const to_page = parseInt(pages?.split(':')[1].replace(")")[0], 10);
    console.log(xnode_id, "pages", pages, "from", from_page, "to_page", to_page);
    try {
      const token = Cookies.get("authToken");
      const response = await fetch(`host/access-res-submitted-v2/?xnode_id=${xnode_id}&from_page=${from_page}&to_page=${to_page}`.replace(
        /host/,
        frontend_host
      ), {
        method: 'GET',
        headers: {
          Authorization: `Basic ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to access the resource');
      }

      const data = await response.json();
      console.log(data);
      const { link_To_File } = data;

      if (link_To_File) {
        const secureFileUrl = link_To_File.replace('http://', 'https://');
        setPdfUrl(secureFileUrl);

        // const secureFileUrl =
        //   process.env.NODE_ENV === 'production'
        //     ? link_To_File.replace('http://', 'https://')
        //     : link_To_File;
        // setPdfUrl(link_To_File);
        setIsReactModalOpen(true); // Open the modal
      } else {
        setError('Unable to retrieve the file link.');
        console.log(error);
      }
    } catch (err) {
      // setError(`Error: ${err.message}`);
      console.log(err);
    } finally {
      // setLoading(false);
    }
  };
  const handleClicks = async (xnode_id_with_pages) => {
    const xnode_id = xnode_id_with_pages?.split(',')[0];
    const pages = xnode_id_with_pages?.split(',')[1];
    const from_page = parseInt(pages?.split(':')[0].split("(")[1], 10);
    const to_page = parseInt(pages?.split(':')[1].replace(")")[0], 10);
    console.log(xnode_id, "pages", pages, "from", from_page, "to_page", to_page);
    try {
      const token = Cookies.get("authToken");
      const response = await fetch(`host/consent-artefact-view-edit/?xnode_id=${xnode_id}`.replace(
        /host/,
        frontend_host
      ), {
        method: 'GET',
        headers: {
          Authorization: `Basic ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to access the resource');
      }

      const data = await response.json();
      console.log(data);
      const { xnode } = data;

      if (xnode) {
        setPdfData(xnode)
      } else {
        // setError('Unable to retrieve the file link.');
        console.log(error);
      }
    } catch (err) {
      setModalMessage({
        message: 'Resource not found.',
        type: 'info',
      });
      setResourceModal(true);
    } finally {
      // setLoading(false);
    }
  };
  console.log("pdfData", pdfData)
  const getTrueKeys = (obj) => {
    return Object.entries(obj)
      .filter(([key, value]) => value === true)
      .map(([key]) => key);
  };
  const postConditionsKeys = getTrueKeys(pdfData?.post_conditions || {});
  const handleRevoke = async (connection_id) => {
    const formData = new FormData();
    formData.append("connection_id", connection_id);
    formData.append("revoke_host_bool", "True");

    // console.log(connection_id ,"id");
    const token = Cookies.get("authToken");
    try {
      // Step 1: Call revoke_host API using fetch
      const revokeHostResponse = await fetch(
        "host/revoke-host/".replace(/host/, frontend_host),
        {
          method: "POST",
          headers: {
            Authorization: `Basic ${token}`,
          },

          body: formData,
        }
      );

      const revokeHostData = await revokeHostResponse.json(); // Parse JSON response

      if (revokeHostResponse.ok) {
        // console.log("Revoke host successful: ", revokeHostData.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }

    // Step 2: Call revoke API using fetch
    try {
      const response = await fetch(
        "host/revoke-guest/".replace(/host/, frontend_host),
        {
          method: "POST",
          headers: {
            // 'Content-Type': 'application/json',
            Authorization: `Basic ${token}`,
          },
          body: formData,
        }
      );

      const data = await response.json();
      // console.log("revoke consent", data);
      if (response.status === 200) {
        return "Successfully revoked ";
      } else {
        return data.message || "An error occurred while revoking consent.";
      }
    } catch (error) {
      console.error("Error:", error);

      return "An error occurred while revoking consent.";
    }
  };

  const handleCloseConnection = async (connection_id) => {
    const formData = new FormData();
    formData.append("connection_id", connection_id);
    // formData.append("close_host_bool", "True");

    // console.log(connection_id ,"id");
    const token = Cookies.get("authToken");
    try {
      // Step 1: Call close_connection_host API using fetch
      const revokeHostResponse = await fetch(
        "host/close_connection_host/".replace(/host/, frontend_host),
        {
          method: "POST",
          headers: {
            Authorization: `Basic ${token}`,
          },

          body: formData,
        }
      );

      const revokeHostData = await revokeHostResponse.json(); // Parse JSON response

      if (revokeHostResponse.ok) {
        // console.log("Revoke host successful: ", revokeHostData.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }

    // Step 2: Call close API using fetch
    try {
      const response = await fetch(
        "host/close_connection_guest/".replace(/host/, frontend_host),
        {
          method: "POST",
          headers: {
            // 'Content-Type': 'application/json',
            Authorization: `Basic ${token}`,
          },
          body: formData,
        }
      );

      const data = await response.json();
      // console.log("revoke consent", data);
      if (response.status === 200) {
        return "Successfully Connection closed ";
      } else {
        return data.message || "An error occurred while Closing connection.";
      }
    } catch (error) {
      console.error("Error:", error);

      return "An error occurred while Closing connection.";
    }
  };

  const handleStatusChange = (index, status, value, type, isFile) => {
    if (value !== "") {
      setStatuses((prevStatuses) => {
        // Update the statuses for the specific index
        const newStatuses = {
          ...prevStatuses,
          [index]: status,
        };

        // Recalculate the resourcesData based on all statuses
        setResourcesData((prevResourcesData) => {
          // Initialize new arrays for transfer and share
          const newTransfer = [...new Set(prevResourcesData.transfer)];
          const newShare = [...new Set(prevResourcesData.share)];
          const newConfer = [...new Set(prevResourcesData.confer)];
          const newCollateral = [...new Set(prevResourcesData.collateral)];

          // const newShare = [];
          // const newTransfer = [];

          // Iterate through all statuses to populate new arrays
          Object.keys(newStatuses).forEach((key) => {
            const currentValue = termsValue[key]?.split(";")[0]; // Extract current value for the term
            const currentType = res.obligations.find(
              (obligation) => obligation.labelName === key
            )?.typeOfSharing;
            const currentIsFile =
              res.obligations.find((obligation) => obligation.labelName === key)
                ?.typeOfAction === "file";

            if (
              newStatuses[key] === "approved" &&
              currentValue &&
              currentIsFile &&
              (!prevStatuses[key] || prevStatuses[key] !== "approved")
            ) {
              if (currentType === "transfer" && !newTransfer.includes(currentValue)) {
                newTransfer.push(currentValue);
              } else if (currentType === "share" && !newShare.includes(currentValue)) {
                newShare.push(currentValue);
              } else if (currentType === "confer" && !newConfer.includes(currentValue)) {
                newConfer.push(currentValue);
              } else if (currentType === "collateral" && !newCollateral.includes(currentValue)) {
                newCollateral.push(currentValue);
              }
            }
          });

          // Return the updated resourcesData
          return {
            transfer: [...new Set(newTransfer)],
            share: [...new Set(newShare)],
            confer: [...new Set(newConfer)],
            collateral: [...new Set(newCollateral)],
          };
        });

        return newStatuses;
      });
    } else {
      alert("Value required in Enter Value field to either Approve or Reject");
    }
  };

  //permissions
  const handleStatusChange2 = (index, status, value, type, isFile) => {

    setStatuses2((prevStatuses) => {
      // Update the statuses for the specific index
      const newStatuses = {
        ...prevStatuses,
        [index]: status,
      };

      // Recalculate the resourcesData based on all statuses
      setResourcesData((prevResourcesData) => {
        // Initialize new arrays for transfer and share
        const newTransfer = [...new Set(prevResourcesData.transfer)];
        const newShare = [...new Set(prevResourcesData.share)];
        const newConfer = [...new Set(prevResourcesData.confer)];
        const newCollateral = [...new Set(prevResourcesData.collateral)];
        // const newTransfer = [];
        // const newShare = [];


        // Iterate through all statuses to populate new arrays
        Object.keys(newStatuses).forEach((key) => {
          const currentValue = permissionsData.find(
            (permission) => permission.labelName === key
          )?.dataElement.split(";")[0];
          console.log(permissionsData, currentValue, "hello") // Extract current value for the term
          const currentType = permissionsData.find(
            (permission) => permission.labelName === key
          )?.share;
          // const currentIsFile =
          //   res.obligations.find((obligation) => obligation.labelName === key)
          //     ?.typeOfAction === "file";

          if (
            newStatuses[key] === "approved" &&
            currentValue &&
            (!prevStatuses[key] || prevStatuses[key] !== "approved")
          ) {
            if (currentType === "transfer" && !newTransfer.includes(currentValue)) {
              newTransfer.push(currentValue);
            } else if (currentType === "share" && !newShare.includes(currentValue)) {
              newShare.push(currentValue);
            } else if (currentType === "confer" && !newConfer.includes(currentValue)) {
              newConfer.push(currentValue);
            } else if (currentType === "collateral" && !newCollateral.includes(currentValue)) {
              newCollateral.push(currentValue);
            }
          }
        });

        // Return the updated resourcesData
        return {
          transfer: [...new Set(newTransfer)],
          share: [...new Set(newShare)],
          confer: [...new Set(newConfer)],
          collateral: [...new Set(newCollateral)],
        };
      });

      return newStatuses;
    });

  };
  console.log("res data", res);
  const handleSave = async () => {
    try {
      const token = Cookies.get("authToken");


      // Create the terms_value object from the obligations
      const terms_value = res?.obligations.reduce((acc, obligation) => {
        console.log(res?.obligations, "data not extra")
        // Determine the status for the current obligation
        const status =
          statuses[obligation.labelName] === "approved"
            ? "T"
            : statuses[obligation.labelName] === "rejected"
              ? "R"
              : "F";
        const resourceName =
          termsValue[obligation.labelName]?.split(";")[0] || "";
        // Add to terms_value with the status
        acc[obligation.labelName] = `${resourceName};${status}`;
        return acc;
      }, {});


      // Preserve the existing canShareMoreData structure without overriding other terms
      if (termsValue?.canShareMoreData) {
        console.log(termsValue.canShareMoreData, "data extra");

        for (const [key, value] of Object.entries(
          termsValue.canShareMoreData
        )) {
          const status =
            statuses2[key] === "approved"
              ? "T"
              : statuses2[key] === "rejected"
                ? "R"
                : "F";
          const val = value.enter_value?.split(";")[0] || "";
          value.enter_value = `${val};${status}`;

        }

        // const extra_terms_value = termsValue?.canShareMoreData.map((acc, obligation) => {
        //   // Determine the status for the current obligation
        //   const status =
        //     statuses[obligation.labelName] === "approved"
        //       ? "T"
        //       : statuses[obligation.labelName] === "rejected"
        //       ? "R"
        //       : "F";
        //   const resourceName =
        //     termsValue[obligation.labelName]?.split(";")[0] || "";
        //   // Add to terms_value with the status
        //   acc[obligation.labelName] = `${resourceName};${status}`;
        //   return acc;
        // }, {});
        terms_value.canShareMoreData = {
          ...termsValue.canShareMoreData,
        };
      }

      // console.log("terms_value", terms_value);

      const resourcesToTransfer = resourcesData.transfer;
      const resourcesToShare = resourcesData.share;
      const resourcesToConfer = resourcesData.confer;
      const resourcesToCollateral = resourcesData.collateral;

      const requestBody = {
        connection_name: conndetails.connection_name,
        host_locker_name: conndetails.host_locker.name,
        guest_locker_name: conndetails.guest_locker.name,
        host_user_username: conndetails.host_user.username,
        guest_user_username: conndetails.guest_user.username,
        terms_value: terms_value,
        resources: {
          Transfer: resourcesToTransfer,
          Share: resourcesToShare,
          Confer: resourcesToConfer,
          Collateral: resourcesToCollateral,
        },
      };

      console.log("Request Body:", requestBody);

      const updateResponse = await fetch(
        `host/update_connection_terms_v2/`.replace(/host/, frontend_host),
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${token}`,
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!updateResponse.ok) {
        const errorText = await updateResponse.text();
        console.error("Error Response:", errorText);
        throw new Error("Failed to save statuses");
      }

      const updateData = await updateResponse.json();
      if (updateData.success) {
        alert("Statuses saved successfully");
      } else {
        setError(updateData.error || "Failed to save statuses");
      }

      for (const resource of resourcesToShare) {
        await updateXnode(resource);
      }
      for (const resource of resourcesToShare) {
        await updateXnode(resource);
      }
      // Transfer resources
      if (resourcesToTransfer.length > 0) {
        await handleAcceptResource();
      }

      // Share resources
      if (resourcesToShare.length > 0) {
        await handleShareResource();
      }

      // Confer resources
      if (resourcesToConfer.length > 0) {
        await handleConferResource();
      }

      if (resourcesToCollateral.length > 0) {
        await handleCollateralResource();
      }

      // navigate("/home");
    } catch (err) {
      console.error("Error:", err.message);
      setError(err.message);
    }
  };

  console.log("conndetials", conndetails);

  // const handleSave = async () => {
  //   try {
  //     // First, call handleCollateralResource and ensure it succeeds
  //     const collateralSuccess = await handleCollateralResource();
  //     if (!collateralSuccess) {
  //       console.log("Collateralization failed, not updating the row.");
  //       return; // Stop execution if collateralization fails
  //     }

  //     const token = Cookies.get("authToken");

  //     // Proceed with processing only if collateralization was successful
  //     const terms_value = res?.obligations.reduce((acc, obligation) => {
  //       console.log(res?.obligations, "data not extra");

  //       const status =
  //         statuses[obligation.labelName] === "approved"
  //           ? "T"
  //           : statuses[obligation.labelName] === "rejected"
  //           ? "R"
  //           : "F";
  //       const resourceName =
  //         termsValue[obligation.labelName]?.split(";")[0] || "";

  //       acc[obligation.labelName] = `${resourceName};${status}`;
  //       return acc;
  //     }, {});

  //     console.log("Collateralization successful, proceeding with save.");
  //     // Continue save logic here...

  //   } catch (err) {
  //     console.error("Error in handleSave:", err);
  //   }
  // };

  const updateXnode = async (resource) => {
    try {
      const token = Cookies.get("authToken");
      const response = await fetch(
        `host/update_inode_v2/`.replace(/host/, frontend_host),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${token}`,
          },
          body: JSON.stringify({
            connection_id: conndetails.connection_id,
            xnode_id: resource.id,
            validity_until: conndetails.validity_until,
          }),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error("Failed to update resource");
      }

      const data = await response.json();
      // console.log("transfer", data);
      if (data.success) {
        console.log("update successful");
      } else {
        setError(data.error || "Failed to transfer resource");
        console.log(data.error);
      }
    } catch (err) {
      setError(err.message);
      console.log(err);
    }
  };

  const handleAcceptResource = async () => {
    try {
      console.log(JSON.stringify({
        connection_name: conndetails.connection_name,
        host_locker_name: conndetails.host_locker.name,
        guest_locker_name: conndetails.guest_locker.name,
        host_user_username: conndetails.host_user.username,
        guest_user_username: conndetails.guest_user.username,
        validity_until: conndetails.validity_time,
      }));

      const token = Cookies.get("authToken");
      const response = await fetch(
        `${frontend_host}/transfer_resource_v2/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${token}`,
          },
          body: JSON.stringify({
            connection_name: conndetails.connection_name,
            host_locker_name: conndetails.host_locker.name,
            guest_locker_name: conndetails.guest_locker.name,
            host_user_username: conndetails.host_user.username,
            guest_user_username: conndetails.guest_user.username,
            validity_until: conndetails.validity_time,
          }),
        }
      );

      // if (!response.ok) {
      //   const errorText = await response.text();
      //   console.log(response.error);
      //   throw new Error("Failed to transfer resource");
      // }

      const data = await response.json();
      // console.log("transfer", data);
      if (data.success) {
        alert(data.message || "Resource transfered successful");
      } else {
        alert("Failed to transfer resource");
      }
    } catch (err) {
      // alert(err.message || "Error occured");
    }
  };

  const handleShareResource = async () => {
    try {
      // console.log(JSON.stringify({
      //   connection_name: conndetails.connection_name,
      //   host_locker_name: conndetails.host_locker.name,
      //   guest_locker_name: conndetails.guest_locker.name,
      //   host_user_username: conndetails.host_user.username,
      //   guest_user_username: conndetails.guest_user.username,
      //   validity_until: conndetails.validity_time,
      // }));

      const token = Cookies.get("authToken");
      const response = await fetch(
        `host/share_resource_approve_v2/`.replace(/host/, frontend_host),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${token}`,
          },
          body: JSON.stringify({
            connection_name: conndetails.connection_name,
            host_locker_name: conndetails.host_locker.name,
            guest_locker_name: conndetails.guest_locker.name,
            host_user_username: conndetails.host_user.username,
            guest_user_username: conndetails.guest_user.username,
            // validity_until: conndetails.validity_time,
          }),
        }
      );



      // if (!response.ok) {
      //   const errorText = await response.text();
      //   console.error("Error Response:", errorText);
      //   throw new Error("Failed to share resource");
      // }

      const data = await response.json();
      if (data.success) {
        alert(data.message || "Resource shared successfully..");
      } else {
        alert(data.error || "Failed to share resource");
      }
    } catch (err) {
      // alert(err.message || "Error occured");
    }
  };

  const handleConferResource = async () => {
    try {
      console.log(JSON.stringify({
        connection_name: conndetails.connection_name,
        host_locker_name: conndetails.host_locker.name,
        guest_locker_name: conndetails.guest_locker.name,
        host_user_username: conndetails.host_user.username,
        guest_user_username: conndetails.guest_user.username,
        validity_until: conndetails.validity_time,
      }));

      const token = Cookies.get("authToken");
      const response = await fetch(
        `${frontend_host}/confer_resource_approve_v2/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${token}`,
          },
          body: JSON.stringify({
            connection_name: conndetails.connection_name,
            host_locker_name: conndetails.host_locker.name,
            guest_locker_name: conndetails.guest_locker.name,
            host_user_username: conndetails.host_user.username,
            guest_user_username: conndetails.guest_user.username,
            // validity_until: conndetails.validity_time,
          }),
        }
      );

      // if (!response.ok) {
      //   const errorText = await response.text();
      //   console.log(response.error);
      //   throw new Error("Failed to confer resource");
      // }

      const data = await response.json();
      if (data.success) {
        alert(data.message || "Resource conferred successful");
      } else {
        alert(data.error || "Failed to confer resource");
      }
    } catch (err) {
      // alert(err.message || "Error occured");
    }
  };

  const handleCollateralResource = async () => {
    try {
      console.log(JSON.stringify({
        connection_name: conndetails.connection_name,
        host_locker_name: conndetails.host_locker.name,
        guest_locker_name: conndetails.guest_locker.name,
        host_user_username: conndetails.host_user.username,
        guest_user_username: conndetails.guest_user.username,
        validity_until: conndetails.validity_time,
      }));

      const token = Cookies.get("authToken");
      const response = await fetch(
        `${frontend_host}/collateral_resource_v2/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${token}`,
          },
          body: JSON.stringify({
            connection_name: conndetails.connection_name,
            host_locker_name: conndetails.host_locker.name,
            guest_locker_name: conndetails.guest_locker.name,
            host_user_username: conndetails.host_user.username,
            guest_user_username: conndetails.guest_user.username,
            validity_until: conndetails.validity_time,
          }),
        }
      );

      // if (!response.ok) {
      //   const errorText = await response.text();
      //   console.log(response.error);
      //   throw new Error("Failed to collateral resource");
      // }

      const data = await response.json();
      if (data.success) {
        alert(data.message || "Resource pledged successful");
      } else {
        alert(data.error || "Failed to collateral resource");
      }
    } catch (err) {
      // alert(err.message || "Error occured");
    }
  };


  // const handleResourceClick = (filePath) => {
  //     const url = `host/media/documents/${filePath}`.replace(/host/, frontend_host);
  //     window.open(url, "_blank");
  // };

  const handleResourceClick = (filePath) => {
    const url = `host/media/${filePath}`.replace(/host/, frontend_host);
    window.open(url, "_blank");
  };

  const openTermsPopup = () => {
    setShowTermsPopup(true);
  };

  const closeTermsPopup = () => {
    setShowTermsPopup(false);
  };
  const handleDownload = async (obligation) => {
    try {
      const token = Cookies.get("authToken");

      // Extract connection details from `conndetails`
      const connectionName = conndetails.connection_name;
      const hostLockerName = conndetails.host_locker.name;
      const guestLockerName = conndetails.guest_locker.name;
      const hostUserUsername = conndetails.host_user.username;
      const guestUserUsername = conndetails.guest_user.username;

      // Extract termsValue for document ID and namePart
      const termsValue = conndetails.terms_value;
      console.log("termsValue:", termsValue);

      let documentId = null;
      let documentName = obligation.labelName;  // Default to labelName in case of no match

      // Check if termsValue contains the document name in a recognizable format
      if (termsValue[documentName]) {
        const termEntry = termsValue[documentName];

        // Check if the entry contains "|" indicating a format like "DocumentName|ID;AdditionalInfo"
        if (termEntry.includes("|")) {
          const [namePart, idPart] = termEntry.split("|");

          // Use namePart from termsValue as document name
          documentName = namePart.trim();
          documentId = idPart ? idPart.split(",")[0].split(";")[0].trim() : null;
        }
      } else {
        console.log("Document entry not found in termsValue for:", documentName);
      }

      // Log the sharing type, extracted document name, and document ID
      console.log("Extracted Document name:", documentName);
      console.log("Sharing type:", obligation.typeOfSharing);
      console.log("Extracted Document ID:", documentId);

      // Prepare payload for the API request with document ID and namePart as document name
      const payload = {
        connection_name: connectionName,
        host_locker_name: hostLockerName,
        guest_locker_name: guestLockerName,
        host_user_username: hostUserUsername,
        guest_user_username: guestUserUsername,
        document_name: documentName,  // Now from `namePart` in termsValue
        sharing_type: obligation.typeOfSharing,
        xnode_id: documentId,  // Document ID from parsed termsValue
      };

      // Log the payload to verify the data before making the request
      console.log("Payload:", payload);

      // Make API call to download resource
      const response = await fetch(`${frontend_host}/download-resource/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${token}`,
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (data.success) {
        alert("Download successful!");

        // Optionally, change the color of the downloaded resource to green
        const downloadedResource = document.getElementById(`resource-${obligation.labelName}`);
        if (downloadedResource) {
          downloadedResource.style.color = "green";
        }

        console.log("Download successful:", data.message);
      } else {
        setError(data.error || "Failed to download resource");
      }
    } catch (err) {
      console.error("Error downloading resource:", err);
      setError(err.message);
    }
  };

  const renderObligations = () => {
    if (res && res.obligations) {
      return (
        <div>
          <h3>Obligations</h3>
          <ul>
            {res.obligations.map((obligation, index) => (
              <li key={index}>
                {obligation.typeOfSharing} - {obligation.labelName} (Host
                Privilege:{" "}
                {obligation.hostPermissions &&
                  obligation.hostPermissions.length > 0
                  ? obligation.hostPermissions.join(", ")
                  : "None"}
                )
              </li>
            ))}
          </ul>
        </div>
      );
    }
    return <p>No obligations available.</p>;
  };

  const renderPermissions = () => {
    if (res && res.permissions) {
      const { canShareMoreData, canDownloadData } = res.permissions;
      return (
        <div className="permissions">
          <h3>Permissions</h3>
          <ul>
            {canShareMoreData ? (
              <li>You can share more data.</li>
            ) : (
              <li>You cannot share more data.</li>
            )}
            {canDownloadData ? (
              <li>You can download data.</li>
            ) : (
              <li>You cannot download data.</li>
            )}
          </ul>
        </div>
      );
    }
    return null;
  };
  const renderForbidden = () => {
    if (res && res.forbidden) {
      return (
        <div className="permissions">
          <h3>Forbidden</h3>
          <ul>
            {res.forbidden.map((term, index) => (
              <li key={index}>{term.labelDescription}</li>
            ))}
          </ul>
        </div>
      );
    }
    return <p>No forbidden terms available.</p>;
  };

  const renderPermissionsTable = () => {
    if (permissionsData.length > 0) {
      return (
        <div className="permissions-table mt-4">
          <h3>User Permissions</h3>
          <table>
            <thead>
              <tr>
                <th>Sno</th>
                <th>Label Name</th>
                <th>Data Element</th>
                <th>Purpose</th>
                <th>Type of Share</th> {/* New column for Type of Share */}
                <th>Consent Artefact</th>
                <th>Status</th> {/* New column for status dropdown */}
              </tr>
            </thead>
            <tbody>
              {permissionsData.map((permission, index) => (
                <tr key={index}>
                  <td>{permission.sno}</td>
                  <td>{permission.labelName}</td>
                  <td> <a className="mb-1"
                    style={{ display: "block", color: "blue", textDecoration: "underline", cursor: "pointer" }}
                    onClick={() =>
                      handleClick(
                        permission.dataElement?.split(";")[0]?.split("|")[1]
                      )
                    }>
                    {permission.dataElement?.split(";")[0]?.split("|")[0]}
                  </a></td>{" "}
                  <td>{permission.purpose || "None"}</td>{" "}
                  <td>{permission.share || "None"}</td>{" "}
                  <td><button onClick={() => openPopup1(permission)}>View</button></td>
                  <td>
                    <select
                      value={statuses2[permission.labelName] || ""}
                      onChange={(e) =>
                        handleStatusChange2(permission.labelName, e.target.value)
                      }
                    >
                      <option value="">Select Status</option>
                      <option value="approved">Approved</option>
                      <option value="rejected">Rejected</option>
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    } else {
      return;
    }
  };

  const navigateToConnectionDetails = (connection) => {
    // Access connection_type_name safely
    const connectionTypeName = conndetails?.connection_name.split("-").shift().trim();


    const connectionDescription = conndetails?.connection_description;

    // Use the owner_locker and owner_user from the connection object
    const hostLockerName = conndetails?.host_locker?.name; // Assuming lockerData has a 'name' property
    const hostUserUsername = conndetails?.host_user?.username;

    const connectionName = conndetails.connection_name;

    // Log the names to verify they're being retrieved correctly
    // console.log("Host Locker Name:", hostLockerName);
    // console.log("Host User Username:", hostUserUsername);
    // console.log("Connection Type:", connectionTypeName);
    // console.log("Description:", connectionDescription);
    // console.log("Connection Name:", connectionName);

    navigate("/display-terms", {
      state: {
        connectionTypeName: connectionTypeName,
        hostLockerName: hostLockerName,
        connectionName: connectionName,
        connectionDescription: connectionDescription,
        createdtime: connection.created_time,
        validitytime: connection.validity_time,
        hostUserUsername: connectionDetails.host_user.username,
        locker: conndetails.host_locker,
        createdtime: connectionDetails.created_time,
        validitytime: connectionDetails.validity_time,
        GuestTermDisplay: true,
        connectionType,
        hostLocker: conndetails.host_locker,
        connectionDetails
      },
    });
  };

  const userTooltips = {
    guest: "Guest",
    host: "Host",
  };


  const renderUserTooltip = (userType) => {
    return (
      <span className="tooltiptext small-tooltip">
        {userTooltips[userType] || "Hover over an icon to see user details."}
      </span>
    );
  };

  const handleGuestClick = () => {

    navigate('/target-locker-view', {
      state: {
        user: { username: conndetails.guest_user.username },
        locker: conndetails.guest_locker,
      }
    });
  };

  const handleHostClick = () => {

    navigate('/view-locker', {
      state: {
        user: { username: conndetails.host_user.username },
        locker: conndetails.host_locker,
      },
    });
  };

  const handleGuestNameClick = () => {
    navigate('/target-user-view', {
      state: {
        user: conndetails.guest_user
      },
    });
  };

  const handleHostNameClick = () => {
    navigate('/home', {
    });

  };

  const handleConnectionClick = () => {
    const lockers = conndetails.host_locker
    const connectionTypes = connectionType
    const hostLockerName = conndetails?.host_locker?.name;
    console.log("navigate show-guest-users", {
      connectionTypes,
      lockers
    });
    navigate("/show-guest-users", { state: { connection: connectionTypes, locker: lockers, hostLocker: conndetails.host_locker, hostUserUsername: curruser.username, hostLockerName } });
  };

  const content = (
    <>
      <div className="navbarBrands">
        {conndetails?.connection_name || "Loading..."}
      </div>
      {/* <div className="navbarBrands"> {curruser ? capitalizeFirstLetter(curruser.username) : "None"}</div>
      <div>
        {curruser ? curruser.description : "None"}
      </div> */}
      {/* <div className="description">
        {curruser ? curruser.description : "None"}
      </div>
      <br></br>
      <div className="connection-details">
        Connection Name: {conndetails?.connection_name || "Loading..."}
        <button
          className="info-button"
          onClick={() => navigateToConnectionDetails(connectionType)}
          title="Show Connection Terms"
          style={{
            marginLeft: "10px",
            cursor: "pointer",
            background: "transparent",
            border: "none",
            marginBottom:"6px"
          }}
        >
          <i className="fa fa-info-circle userIcon"></i>
        </button>
        <br></br>
        <span>{conndetails?.connection_description}</span>
        <br></br>
        <div className="tooltip-container user-container">
          <div className="tooltip user-container">
            <FaUserCircle className="userIcon"/> &nbsp;
            <span className="userName">{renderUserTooltip('guest',conndetails.guest_user?.username)} : {conndetails.guest_user?.username||"Loading..."} &nbsp;</span>
          </div>
          <i class="fa-solid fa-right-long"></i> &nbsp;
          <div className="tooltip user-container">
            <FaRegUserCircle className="userIcon"/>&nbsp;
            <span className="userName">{renderUserTooltip('host',conndetails.host_user?.username)} : {conndetails?.host_user?.username||"Loading..."}</span>
          </div>
        </div>
        <div className="tooltip-container user-container">
          <div className="tooltip user-container" onClick={() => navigate("/home")} style={{ cursor: 'pointer' }}>
            <i class="bi bi-person-fill-lock"></i> &nbsp;
            <span className="userName">{renderUserTooltip('guest',conndetails.guest_locker?.name)} : {conndetails.guest_locker?.name||"Loading..."} &nbsp;</span>
          </div>
          <i class="fa-solid fa-right-long"></i> &nbsp;
          <div className="tooltip user-container" >
            <i class="bi bi-person-lock"></i>&nbsp;
            <span className="userName">{renderUserTooltip('host',conndetails.host_locker?.name)} : {conndetails.host_locker?.name||"Loading..."}</span>
          </div>
        </div>
      </div> */}
    </>
  );

  const breadcrumbs = (
    <div className="breadcrumbs">
      <a href="/home" className="breadcrumb-item">
        Home
      </a>
      <span className="breadcrumb-separator">▶</span>
      <span onClick={() => handleHostClick()} className="breadcrumb-item">View Locker</span>
      <span className="breadcrumb-separator">▶</span>
      <span onClick={() => handleConnectionClick()} className="breadcrumb-item">ShowGuestUsers</span>
      <span className="breadcrumb-separator">▶</span>
      <span className="breadcrumb-item current">GuestTermsReview</span>
    </div>
  )

  // const uniqueGlobalConnTypeIds = [
  //   ...new Set(
  //     terms
  //       .filter((term) => term.global_conn_type_id !== null)
  //       .map((term) => term.global_conn_type_id)
  //   ),
  // ];


  const termsArray = [...(terms.guest_to_host || []), ...(terms.host_to_guest || [])];
  // Access guest_to_host array, fallback to empty array

  // Log the termsArray for debugging
  console.log("Terms Array:", termsArray);

  // Ensure you filter and map properly over the array
  const uniqueGlobalConnTypeIds = Array.isArray(termsArray) ? [
    ...new Set(
      termsArray
        .filter(term => term.global_conn_type_id !== null && term.global_conn_type_id !== undefined)
        .map(term => term.global_conn_type_id)
    )
  ] : [];


  const globalTemplateNames = uniqueGlobalConnTypeIds.map((id) => {
    const template = globalTemplates.find(
      (template) => template.global_connection_type_template_id === id
    );
    return template ? template.global_connection_type_name : null;
  });
  const [isReceiptChecked, setIsReceiptChecked] = useState(false); // State for the checkbox

  const handleCheckboxChange = () => {
    setIsReceiptChecked(!isReceiptChecked); // Toggle checkbox state
  };
  const handleNavigation = (template) => {
    if (template) {
      console.log("temp", template);
      console.log("id", template.global_connection_type_template_id);
      navigate('/GlobalTermsView', {
        state: {
          connectionTypeName: template.global_connection_type_name,
          connectionTypeDescription: template.global_connection_type_description,
          template_Id: template.global_connection_type_template_id,
          hide: true,
        },
      });
    }
  };
  const tooltips = {
    share: "You are not transferring ownership of this resource, but the recipient can view your resource. The recipient cannot do anything else.",
    transfer: "You are transferring ownership of this resource. You will no longer have access to this resource after this operation.",
    confer: "You are going to transfer ownership of the resource, but the recipient cannot modify the contents. You still have rights over this resource.",
    collateral: "You are temporarily transferring ownership to the recipient. After this operation, you cannot change anything in the resource."
  };

  const renderTooltip = (typeOfShare) => {
    return (
      <span className="tooltiptext">
        {tooltips[typeOfShare] || "Select a type of share to view details."}
      </span>
    );
  };

  const handleClose = () => {
    setIsReactModalOpen(false);
    setPdfUrl(null);
  };

  console.log("conn details", conndetails);
  console.log("connection", connection);
  console.log("navigate back 1", connection);
  console.log("navigate back 2", connectionType);
  return (
    <div>
      <Navbar content={content} breadcrumbs={breadcrumbs} />

      <div style={{ marginTop: '140px' }}>
        <div className="connection-details">
          <b>Connection Name:</b> {conndetails?.connection_name || "Loading..."}
          <button
            className="info-button"
            onClick={() => navigateToConnectionDetails(connectionType)}
            title="Show Connection Terms"
            style={{
              marginLeft: "10px",
              cursor: "pointer",
              background: "transparent",
              border: "none",
              marginBottom: "6px"
            }}
          >
            <i className="fa fa-info-circle userIcon"></i>
          </button>
          <br></br>
          <span>{conndetails?.connection_description}</span>
          <br></br>
          <Grid container>
            <Grid item xs={12} md={10}>
              <div className="tooltip-container user-container">
                <div className="tooltips user-container" onClick={() => handleGuestNameClick()}>
                  <i className="guestuser-icon" /> &nbsp;
                  {/* <FaUserCircle className="userIcon" /> &nbsp; */}
                  <span className="userName">: {capitalizeFirstLetter(conndetails.guest_user?.username) || "Loading..."} &nbsp;</span>
                </div>
                <i class="fa-solid fa-right-long mt-1"></i> &nbsp;
                <div className="tooltips user-container" onClick={() => handleHostNameClick()}>
                  <i className="hostuser-icon" /> &nbsp;
                  {/* <FaRegUserCircle className="userIcon" />&nbsp; */}
                  <span className="userName">: {capitalizeFirstLetter(conndetails?.host_user?.username) || "Loading..."}</span>
                </div>
              </div>
              <div className="tooltip-container user-container">
                <div className="tooltips user-container" onClick={() => handleGuestClick()} style={{ cursor: 'pointer' }}>
                  <i className="guestLocker-icon" />
                  {/* <i class="bi bi-person-fill-lock"></i> &nbsp; */}
                  <span className="userName"> : {conndetails.guest_locker?.name || "Loading..."} &nbsp;</span>
                </div>
                <i class="fa-solid fa-right-long mt-1"></i> &nbsp;
                <div className="tooltips user-container" onClick={() => handleHostClick()}>
                  <i className="hostLocker-icon" />
                  <span className="userName"> : {conndetails.host_locker?.name || "Loading..."}</span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={1.5}>
              {connection && (() => {
                const tracker = trackerData[connection.connection_id];
                const color = tracker ? getStatusColor(tracker) : "gray";
                const ratio = tracker ? calculateRatio(tracker) : "Loading...";

                const trackerReverse = trackerDataReverse[connection.connection_id];
                const colorReverse = trackerReverse ? getStatusColorReverse(trackerReverse) : "gray";
                const ratioReverse = trackerReverse ? calculateRatioReverse(trackerReverse) : "Loading...";

                return (
                  <Grid container key={connection.connection_id}>

                    <Grid item xs={12} style={{ paddingTop: "10px" }}>
                      <div className="d-flex align-items-center">
                        <h6 className="mt-2 me-2">
                          {capitalizeFirstLetter(connection.guest_user.username)}
                        </h6>
                        <i className="bi bi-arrow-right me-2" style={{ fontSize: "1.2rem" }}></i>
                        <button
                          // onClick={() => handleTracker(connection)}
                          style={{
                            backgroundColor: color,
                            border: "none",
                            padding: "5px 10px",
                            borderRadius: "5px",
                            color: "#fff",
                            cursor: "pointer",
                          }}
                        >
                          {ratio}
                        </button>
                      </div>

                      <div className="d-flex align-items-center mt-1">
                        <button
                          className="me-2"
                          // onClick={() => handleTrackerHost(connection)}
                          style={{
                            backgroundColor: colorReverse,
                            border: "none",
                            padding: "5px 10px",
                            borderRadius: "5px",
                            color: "#fff",
                            cursor: "pointer",
                          }}
                        >
                          {ratioReverse}
                        </button>
                        <i className="bi bi-arrow-left me-2" style={{ fontSize: "1.2rem" }}></i>
                        <h6 className="mt-2">{capitalizeFirstLetter(connection.host_user.username)}</h6>
                      </div>
                    </Grid>
                  </Grid>
                );
              })()}
            </Grid>
          </Grid>
        </div>

        <div className="view-containers">
          <div className="b">
            <div className="tabs">
              <div
                className={`tab-header ${activeTab === "guest" ? "active" : ""
                  }`}
                onClick={() => setActiveTab("guest")}
              >
                Guest Data
              </div>
              <div
                className={`tab-header ${activeTab === "host" ? "active" : ""
                  }`}
                onClick={() => navigate("/view-host-terms-by-type", {
                  state: {
                    connection_id: conndetails.connection_id,
                    connectionName: conndetails.connection_name,
                    connectionDescription: conndetails.connection_description,
                    hostLockerName: conndetails?.host_locker?.name,
                    guestLockerName: conndetails?.guest_locker?.name,
                    hostUserUsername: conndetails?.host_user?.username,
                    guestUserUsername: conndetails?.guest_user?.username,
                    locker: conndetails?.host_locker,
                    guest_locker_id: conndetails.guest_locker?.locker_id,
                    host_locker_id: conndetails.host_locker?.locker_id,
                    connection: connection,
                    connectionType: connectionType,
                    guestLocker: conndetails.guest_locker,
                    hostLocker: conndetails.host_locker
                  },
                })}
              >
                Shared by me
              </div>
            </div>
            {/* Added Tabs */}
            {/* Added Tabs */}
            <div className="tab-content">
              {activeTab == "guest" && (
                <>
                  <div className={showResources ? "split-view" : ""}>
                    <div className="table-container">
                      <div className="center2">
                        {globalTemplateNames.length > 0 && "Regulations used: "}
                        <span style={{ fontWeight: "bold" }}>
                          {uniqueGlobalConnTypeIds.map((id, index) => {
                            const template = globalTemplates.find(template => template.global_connection_type_template_id === id);
                            return template ? (
                              <span
                                key={index}
                                onClick={() => handleNavigation(template)}  // Pass the entire template object
                                style={{ cursor: "pointer", textDecoration: "underline" }}  // Indicate it's clickable
                              >
                                {template.global_connection_type_name}
                                {index < uniqueGlobalConnTypeIds.length - 1 && ", "}
                              </span>
                            ) : null;
                          })}
                        </span>
                      </div>

                      <Grid container>
                        <Grid item md={10} xs={12}>
                          <h3>Guest Obligations</h3>
                        </Grid>

                        {/* <Grid item md={2} xs={12}>
                          <button onClick={openTermsPopup} className="">
                            View Terms
                          </button>
                        </Grid> */}
                      </Grid>
                      {showTermsPopup && (
                        <div className="terms-popup">
                          <div className="terms-popup-content">
                            <span className="close" onClick={closeTermsPopup}>
                              &times;
                            </span>
                            <h2>Connection Terms</h2>
                            {renderObligations()}
                            {renderPermissions()}
                            {renderForbidden()}
                            <div className="permissions">
                              <h3>Default Host Privileges</h3>
                              By default Reshare,Download,Aggreagte are disabled unless
                              otherwise mentioned in the terms
                            </div>
                            <div className="permissions">
                              <h3>Host Obligations</h3>
                              You will receive a receipt when all the obligations are met.


                            </div>
                          </div>
                        </div>
                      )}
                      <table>
                        <thead>
                          <tr>
                            <th>Sno</th>
                            <th>Name</th>
                            <th>Data Element</th>
                            <th>Purpose</th>
                            <th>Type of Share</th>
                            {/* <th>Host Privileges</th> */}
                            <th>Consent Artefact</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {res?.obligations.map((obligation, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{obligation.labelName}</td>
                              <td>
                                {termsValue[obligation.labelName]?.split(";")[0] ? (
                                  <a
                                    style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
                                    onClick={() =>
                                      handleClick(
                                        termsValue[obligation.labelName]?.split(";")[0]?.split("|")[1]
                                      )
                                    }
                                  >
                                    {termsValue[obligation.labelName]?.split(";")[0]?.split("|")[0]}
                                  </a>
                                ) : (
                                  "None"
                                )}
                                <ReactModal
                                  isOpen={isReactModalOpen}
                                  onRequestClose={handleClose}
                                  contentLabel="PDF Viewer"
                                  style={{
                                    content: {
                                      top: "55%",
                                      left: "50%",
                                      right: "auto",
                                      bottom: "auto",
                                      marginRight: "-50%",
                                      transform: "translate(-50%, -50%)",
                                      width: "95%",
                                      height: "80%",
                                      overflowY: "hidden",
                                      maxWidth: "100%", // Ensure it doesn't overflow on smaller screens
                                      maxHeight: "90%", // Max height for larger screens
                                    },
                                  }}
                                >
                                  <button
                                    onClick={handleClose}
                                    style={{
                                      marginBottom: "10px",
                                      cursor: "pointer",
                                      position: "absolute",
                                      top: "10px",
                                      right: "10px", // Button positioned at the top right
                                      zIndex: 100,
                                    }}
                                  >
                                    Close
                                  </button>
                                  {pdfUrl ? (
                                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                                      <Viewer fileUrl={pdfUrl} />
                                    </Worker>
                                  ) : (
                                    <p>Loading PDF...</p>
                                  )}
                                </ReactModal>
                              </td>
                              <td>{obligation.purpose}</td>
                              <td>
                                <div className="tooltips">
                                  {obligation.typeOfSharing}
                                  {renderTooltip(obligation.typeOfSharing)}
                                </div>
                              </td>
                              {/* <td>
                                {obligation.hostPermissions
                                  ? obligation.hostPermissions.join(", ")
                                  : "None"}
                              </td> */}
                              <td> <button onClick={() => openPopup(obligation)}>View</button></td>
                              <td>
                                <select
                                  value={statuses[obligation.labelName] || ""}
                                  onChange={(e) =>
                                    handleStatusChange(
                                      obligation.labelName,
                                      e.target.value,
                                      termsValue[obligation.labelName]?.split(";")[0],
                                      obligation.typeOfSharing,
                                      obligation.typeOfAction === "file"
                                    )
                                  }
                                >
                                  <option value="">Select Status</option>
                                  <option value="approved">Approved</option>
                                  <option value="rejected">Rejected</option>
                                </select>
                              </td>
                              <td>
                                {obligation.hostPermissions && obligation.hostPermissions.includes("download") ? (
                                  <button onClick={() => handleDownload(obligation)} className="download-button">
                                    <i className="fa fa-download" aria-hidden="true"></i>
                                  </button>
                                ) : (
                                  " "
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      {/* Permissions Table Rendered Here */}
                      {renderPermissionsTable()}
                    </div>
                    {showResources && (
                      <div className="resource-container">
                        <h3>Resource List</h3>
                        <ul>
                          {resources.map((resource, index) => (
                            <li key={index} onClick={() => handleResourceClick(resource)}>
                              {resource}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  <br></br>
                  <div className="save-button-container">
                    <button onClick={handleSave}>Save</button>
                  </div>

                  {/* <div style={{ marginTop: '20px', marginLeft: '10px' }}>
                    <h3 style={{ fontSize: '20px', marginLeft: '10px' }}>Host Obligations</h3>
                    <label style={{ fontSize: '20px', marginLeft: '10px' }}>
                      The guest will receive a receipt once all the documents are received.
                      <input
                          type="checkbox"
                          checked={isReceiptChecked}
                          onChange={handleCheckboxChange}
                          style={{ transform: 'scale(1.5)', marginLeft: '10px' }}
                      />
                    </label>
                    </div> */}



                  {isModalOpen && (
                    <Modal
                      message={modalMessage.message}
                      onClose={handleCloseModal}
                      type={modalMessage.type}
                      revoke={revokeState}
                      onRevoke={() => onRevokeButtonClick(conndetails.connection_id)}
                      viewTerms={() => navigateToConnectionDetails(connectionType)}
                    />
                  )}

                  {isModalOpenClose && (
                    <Modal
                      message={modalMessage.message}
                      onClose={handleCloseModalClose}
                      type={modalMessage.type}
                      closeConnection={closeState}
                      onCloseConnection={() => onCloseButtonClick(conndetails.connection_id)}
                      viewTerms={() => navigateToConnectionDetails(connectionType)}
                    />
                  )}

                  {isModalOpens && (
                    <Modal
                      message={modalMessage.message}
                      onClose={handleCloseModal}
                      type={modalMessage.type}
                    />
                  )}

                  {/* {isRevokeModalOpen && (
                    <RevokeMessageModal 
                    message={revokeMessage} 
                    onClose={handleCloseModal}
                    />
                    )} */}

                </>
              )}
              {/* {activeTab=="host" &&(
                  <>
                    <div className="table-container">
                      <h3>Host Obligations</h3>
                        <table>
                          <thead>
                            <tr>
                              <th>Sno</th>
                              <th>Name</th>
                              <th>purpose</th>
                              <th>Type of share</th>
                              <th>Enter value</th>
                              <th>Host Privileges</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                        </table>
                    </div>
                    <div style={{ margin: "10px 0" }}>
                    <div>
                      {
                        <button style={{ marginLeft: "10px" }}>
                          Submit
                        </button>
                      }
                    </div>
                  </div>
                  </>
                )} */}
            </div>
          </div>
        </div>
        {resourceModal && (
          <Modal
            message={modalMessage.message}
            onClose={handleCloseResourceModal}
            type={modalMessage.type}
          />
        )}

        {showOpenPopup && selectedRowData && pdfData && (
          <>
            <div className="edit-modal " style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
            >
              <div className="modal-content">
                {/* Close Button */}
                <div className="close-detail">
                  <button
                    type="button"
                    className="position-absolute top-0 end-0 m-2 d-flex align-items-center justify-content-center border-0 bg-transparent"
                    onClick={() => closeOpenPopup()}
                    style={{
                      width: "32px",
                      height: "32px",
                      borderRadius: "50%",
                      backgroundColor: "#f8d7da", // Light red for a subtle look
                      color: "#721c24", // Darker red for contrast
                      boxShadow: "0 3px 10px rgba(0, 0, 0, 0.2)",
                      cursor: "pointer",
                      transition: "0.3s ease-in-out",
                    }}
                    aria-label="Close"
                  >
                    <i className="bi bi-x-lg" style={{ fontSize: "18px" }}></i>
                  </button>
                </div>
                <h5 className="fw-bold  mb-1">Consent Artefact</h5>

                <div className="card p-3 shadow-lg border-0">
                  {termsValue[selectedRowData.labelName]?.split(";")[0] ? (
                    <>
                      <div className="d-flex justify-content-between border-bottom pb-2">
                        <span className="fw-bold">File Name:</span>
                        <span> {termsValue[selectedRowData.labelName]?.split(";")[0]?.split("|")[0]}</span>
                      </div>
                      {pdfData ? (
                        <>
                          <div className="d-flex justify-content-between border-bottom py-2">
                            <span className="fw-bold">Created on:</span>
                            <span>{new Date(pdfData.created_at).toLocaleString()}</span>
                          </div>
                          <div className="d-flex justify-content-between border-bottom py-2">
                            <span className="fw-bold">Validity until:</span>
                            <span>{new Date(pdfData.validity_until).toLocaleString()}</span>
                          </div>
                          <div className="d-flex justify-content-between border-bottom py-2">
                            <span className="fw-bold">Current owner:</span>
                            <span>{capitalizeFirstLetter(pdfData.current_owner_username) || "N/A"}</span>
                          </div>
                          <div className="d-flex justify-content-between border-bottom py-2">
                            <span className="fw-bold">Type of Share:</span>
                            <span>{selectedRowData.typeOfSharing}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between border-bottom py-2 align-items-center">
                            <span className="fw-bold">Post Conditions:</span>
                            <span className=" text-end">
                              {postConditionsKeys.length > 0 ? postConditionsKeys.join(", ") : "No conditions found"}
                            </span>
                          </div>
                        </>
                      ) : (
                        <p>Loading...</p>
                      )}
                    </>
                  ) : (
                    "None"
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        {showOpenPopup && selectedRowData1 && pdfData && (
          <>
            <div className="edit-modal " style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
            >
              <div className="modal-content">
                {/* Close Button */}
                <div className="close-detail">
                  <button
                    type="button"
                    className="position-absolute top-0 end-0 m-2 d-flex align-items-center justify-content-center border-0 bg-transparent"
                    onClick={() => closeOpenPopup()}
                    style={{
                      width: "32px",
                      height: "32px",
                      borderRadius: "50%",
                      backgroundColor: "#f8d7da", // Light red for a subtle look
                      color: "#721c24", // Darker red for contrast
                      boxShadow: "0 3px 10px rgba(0, 0, 0, 0.2)",
                      cursor: "pointer",
                      transition: "0.3s ease-in-out",
                    }}
                    aria-label="Close"
                  >
                    <i className="bi bi-x-lg" style={{ fontSize: "18px" }}></i>
                  </button>
                </div>
                <h5 className="fw-bold  mb-1">Consent Artefact</h5>

                <div className="card p-3 shadow-lg border-0">
                  {selectedRowData1.dataElement ? (
                    <>
                      <div className="d-flex justify-content-between border-bottom pb-2">
                        <span className="fw-bold">File Name:</span>
                        <span> {selectedRowData1.dataElement?.split(";")[0]?.split("|")[0]}</span>
                      </div>
                      {pdfData ? (
                        <>
                          <div className="d-flex justify-content-between border-bottom py-2">
                            <span className="fw-bold">Created on:</span>
                            <span>{new Date(pdfData.created_at).toLocaleString()}</span>
                          </div>
                          <div className="d-flex justify-content-between border-bottom py-2">
                            <span className="fw-bold">Validity until:</span>
                            <span>{new Date(pdfData.validity_until).toLocaleString()}</span>
                          </div>
                          <div className="d-flex justify-content-between border-bottom py-2">
                            <span className="fw-bold">Current owner:</span>
                            <span>{capitalizeFirstLetter(pdfData.current_owner_username) || "N/A"}</span>
                          </div>
                          <div className="d-flex justify-content-between border-bottom py-2">
                            <span className="fw-bold">Type of Share:</span>
                            <span>{selectedRowData1.share}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between border-bottom py-2 align-items-center">
                            <span className="fw-bold">Post Conditions:</span>
                            <span className=" text-end">
                              {postConditionsKeys.length > 0 ? postConditionsKeys.join(", ") : "No conditions found"}
                            </span>
                          </div>
                        </>
                      ) : (
                        <p>Loading...</p>
                      )}
                    </>
                  ) : (
                    "None"
                  )}
                </div>
              </div>
            </div>
          </>
        )}

      </div>


    </div>
  );
};

export default Guesttermsreview;