// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panel { 
    /* width: 200px;
    background-color: #f4f4f4;
    height: 100vh;
    position: fixed;
    background-color: #73b7ff; */
    width: 300px;
    background-color: #73b7ff;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 220px;
    border-radius: 1em;
}

/* .page-container {
    display: flex;
} */

/* .Panelcontent {
    margin-left: 220px;
    padding: 20px;
    width: 100%;
} */

.panel ul {
    list-style-type: none;
    padding: 0;
    font-weight: bold;
  }
  
  .panel ul li {
    padding: 40px;
    text-align: center;
    font-size: large;
  }
  
  .panel ul li a {
    color: rgb(0, 0, 0);
    text-decoration: none;
    display: block;
  }
  
  .panel ul li.selected {
    background-color: #c5e1ff;
    border-radius: 1em;
  }
`, "",{"version":3,"sources":["webpack://./src/components/Panel/Panel.css"],"names":[],"mappings":"AAAA;IACI;;;;gCAI4B;IAC5B,YAAY;IACZ,yBAAyB;IACzB,aAAa;IACb,eAAe;IACf,MAAM;IACN,OAAO;IACP,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;;GAEG;;AAEH;;;;GAIG;;AAEH;IACI,qBAAqB;IACrB,UAAU;IACV,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;IACnB,qBAAqB;IACrB,cAAc;EAChB;;EAEA;IACE,yBAAyB;IACzB,kBAAkB;EACpB","sourcesContent":[".panel { \n    /* width: 200px;\n    background-color: #f4f4f4;\n    height: 100vh;\n    position: fixed;\n    background-color: #73b7ff; */\n    width: 300px;\n    background-color: #73b7ff;\n    height: 100vh;\n    position: fixed;\n    top: 0;\n    left: 0;\n    padding-top: 220px;\n    border-radius: 1em;\n}\n\n/* .page-container {\n    display: flex;\n} */\n\n/* .Panelcontent {\n    margin-left: 220px;\n    padding: 20px;\n    width: 100%;\n} */\n\n.panel ul {\n    list-style-type: none;\n    padding: 0;\n    font-weight: bold;\n  }\n  \n  .panel ul li {\n    padding: 40px;\n    text-align: center;\n    font-size: large;\n  }\n  \n  .panel ul li a {\n    color: rgb(0, 0, 0);\n    text-decoration: none;\n    display: block;\n  }\n  \n  .panel ul li.selected {\n    background-color: #c5e1ff;\n    border-radius: 1em;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
