import React, { useContext, useEffect, useState } from 'react';
import "./CreateConnectionType.css";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from 'js-cookie';
import { usercontext } from "../../usercontext";
import Navbar from '../Navbar/Navbar';
import { frontend_host } from '../../config';
import { Grid, Box, Button } from '@mui/material'

export const CreateConnectionType = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { curruser, setUser } = useContext(usercontext);
    const [isOpen, setIsOpen] = useState(false);
    const [lockers, setLockers] = useState([]); // Initialize as empty array
    const [error, setError] = useState(null);
    const [selectedLocker, setSelectedLocker] = useState(null);
    const [connectionTypes, setConnectionTypes] = useState([]); // Initialize as empty array
    // const [selectedConnectionType, setSelectedConnectionType] = useState(null);
    const [parentUser, setParentUser] = useState(location.state ? location.state.hostuser : null);
    const [locker, setLocker] = useState(location.state ? location.state.hostlocker : null);
    const [selectedConnectionType, setSelectedConnectionType] = useState(location.state ? location.state.selectedConnectionType : null);


    useEffect(() => {
        if (!curruser) {
            navigate('/');
            return;
        }

        const token = Cookies.get('authToken');
        fetch('host/get-lockers-user/'.replace(/host/, frontend_host), {
            method: 'GET',
            headers: {
                'Authorization': `Basic ${token}`,
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setLockers(data.lockers || []); // Ensure array
                    if (!selectedLocker && data.lockers.length > 0) {
                        setSelectedLocker(data.lockers[0]);
                    }
                } else {
                    setError(data.message || data.error);
                }
            })
            .catch(error => {
                setError("An error occurred while fetching lockers.");
                console.error("Error:", error);
            });
    }, [curruser]);

    useEffect(() => {
        if (parentUser && locker) {
            const token = Cookies.get('authToken');
            const params = new URLSearchParams({ guest_locker_name: locker.name, guest_username: parentUser.username });
            fetch(`host/get-other-connection-types/?${params}`.replace(/host/, frontend_host), {
                method: 'GET',
                headers: {
                    'Authorization': `Basic ${token}`,
                    'Content-Type': 'application/json'
                }
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        setConnectionTypes(data.connection_types || []); // Ensure array
                        if (!selectedConnectionType && data.connection_types.length > 0) {
                            setSelectedConnectionType(data.connection_types[0]);
                        }
                    } else {
                        setError(data.message || data.error);
                    }
                })
                .catch(error => {
                    setError("An error occurred while fetching connection types.");
                    console.error("Error:", error);
                });
        }
    }, [parentUser, locker]);

    const handleLockerChange = (event) => {
        const selectedLockerName = event.target.value;
        const locker = lockers.find(l => l.name === selectedLockerName);
        setSelectedLocker(locker);
    };

    const handleConnectionTypeChange = (event) => {
        const selectedConnectionTypeName = event.target.value;
        const connectionType = connectionTypes.find(ct => ct.connection_type_name === selectedConnectionTypeName);
        setSelectedConnectionType(connectionType);
    };

    console.log("locker", locker.name);
    const handleNextClick = (event) => {
        event.preventDefault(); // Prevent the default form submission

        // Ensure all required data is present
        if (!selectedConnectionType || !parentUser || !curruser || !locker || !selectedLocker) {
            console.error('Missing necessary data to proceed');
            setError("Required data is missing.");
            return;
        }

        console.log('Navigating to show connection terms');

        // Navigate to the terms page with state data
        navigate('/show-connection-terms', {
            state: {
                connectionTypeName: selectedConnectionType.connection_type_name,
                connectionDescription: selectedConnectionType.connection_description,
                locker: selectedLocker.name,
                guestUserUsername:curruser.username,
                hostUserUsername: parentUser.username,
                hostLockerName: locker.name,
                guestLockerName: selectedLocker.name,
                connectionName: `${selectedConnectionType.connection_type_name}-${curruser.username}:${parentUser.username}`,
                showConsent: true,
                lockerComplete: selectedLocker,
                hostLocker:locker
            }
        });
    };


    console.log(connectionTypes);
    const content = (
        <>
            <select className="navbarBrands" style={{ fontSize: "20px", marginTop: "5px", padding: "8px", borderRadius: "5px" }} name="connectionType" onChange={handleConnectionTypeChange} value={selectedConnectionType ? selectedConnectionType.connection_type_name : ''}>
                <option value="" disabled>Select Connection Type</option>
                {connectionTypes && connectionTypes.map(type => (
                    <option key={type.connection_type_id} value={type.connection_type_name}>{type.connection_type_name}</option>
                ))}
            </select>
        </>

    )
    return (
        <div id='make-connection'>
            <Navbar content={content} />
            <div style={{ marginTop: "120px" }}>
                <div className="page12typeofconn">
                    <h6>
                        {selectedConnectionType && <div><b>{selectedConnectionType.connection_type_name} ({curruser.username} <i class="bi bi-arrows"></i> {parentUser.username})</b> <p className="">Description: {selectedConnectionType.connection_description}</p></div>}
                    </h6>
                </div>
                <div style={{border:"2px solid blue",margin:"20px"}}>
                <Grid container className="page12parentconnections">
                    <Grid item md={2.5} sm={4} xs={12}>
                        <Box className="make-Box"
                            sx={{
                                padding: '5px 10px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                boxShadow: 3,
                                borderRadius: 2,
                                backgroundColor: "#f9f9f9;",
                                border: "2px solid rgb(107, 120, 231)",
                                paddingTop:"18px"
                            }}
                        >
                            <p>Host User: {parentUser.username}</p><p style={{marginTop:"-12px"}}>Host Locker: {locker.name}</p>
                        </Box>
                    </Grid>
                    <Grid item md={1} sm={2} xs={12}>

                    </Grid>
                    <Grid item md={3.7} sm={6} xs={12}>
                    <Box className="make-Box"  marginTop={{ xs: "15px", sm: "15px", md: 0,}}
                            sx={{
                                padding: '5px 10px',
                                display: 'flex',
                                // flexDirection: 'column',
                                // justifyContent: 'center',
                                boxShadow: 3,
                                borderRadius: 2,
                                backgroundColor: "#f9f9f9;",
                                border: "2px solid rgb(107, 120, 231)",
                                // paddingTop:"18px",
                                paddingBottom:"18px"
                            }}
                        >
                           <Grid container>
                           <Grid item md={4} sm={12} xs={12}>
                                <p className='selectLocker'>Select Your Locker</p>
                           </Grid>
                           <Grid item md={6.5} sm={12} xs={12}>
                           <select className="page12hostlocker" name="locker" onChange={handleLockerChange} value={selectedLocker ? selectedLocker.name : ''}>
                            {lockers && lockers.map(locker => (
                                <option key={locker.locker_id} value={locker.name}>{locker.name}</option>
                            ))}
                        </select>
                           </Grid>
                           </Grid>
                            </Box>
                    </Grid>
                </Grid>
                {selectedConnectionType && (
                   <div style={{margin:"30px"}}>
                     <Grid container className="page12paragraph" display={"flex"}>
                        {/* <u>"{selectedConnectionType.connection_type_name}"</u><span style={{marginLeft:"15px", marginRight:"15px"}}>For this connection type you will need to fulfill the following obligations. Click on the next button.</span> */}
                    <span>Click next to fulfill the obligations of this connection.</span>

                        <div>
                        <Button style={{marginLeft:"10px"}} onClick={handleNextClick} className="next-btn">Next</Button>
                        </div>
                    </Grid>
                   </div>
                )}
                </div>
            </div>

        </div>
    );
};