// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page2heroContainer {
  border: 2px solid #4285f4;
  display: flex;
  margin: 3rem;
  justify-content: center;
  align-items: center;
  height: calc(80vh - 4rem);
}

.page2lockerForm {
  display: flex;
  flex-direction: column;
  width: 85%;
}

.page2lockerForm label {
  display: flex;
  flex-direction: row;
  margin-bottom: 5rem;
}

.page2lockerForm input {
  padding: 0.5rem;
  border: 2px solid #4285f4;
  border-radius: 0.25rem;
  width: 55%;
}

.page2lockerForm button {
  background-color: #4285f4;
  color: #fff;
  width: auto;
  border: none;
  padding: 1rem 0.75rem;
  border-radius: 0.25rem;
  cursor: pointer;
  font-weight: bolder;
  max-width: 200px;
}

.page2lockerForm label span {
  margin-right: 5rem;
  font-size: 1.45rem;
  font-weight: bolder;
  flex: 0.3 1;
}

.page2lockerForm button:hover {
  background-color: #3367d6;
}


`, "",{"version":3,"sources":["webpack://./src/components/CreateLocker/page2.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;EACb,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,yBAAyB;EACzB,sBAAsB;EACtB,UAAU;AACZ;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,WAAW;EACX,YAAY;EACZ,qBAAqB;EACrB,sBAAsB;EACtB,eAAe;EACf,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,mBAAmB;EACnB,WAAS;AACX;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".page2heroContainer {\n  border: 2px solid #4285f4;\n  display: flex;\n  margin: 3rem;\n  justify-content: center;\n  align-items: center;\n  height: calc(80vh - 4rem);\n}\n\n.page2lockerForm {\n  display: flex;\n  flex-direction: column;\n  width: 85%;\n}\n\n.page2lockerForm label {\n  display: flex;\n  flex-direction: row;\n  margin-bottom: 5rem;\n}\n\n.page2lockerForm input {\n  padding: 0.5rem;\n  border: 2px solid #4285f4;\n  border-radius: 0.25rem;\n  width: 55%;\n}\n\n.page2lockerForm button {\n  background-color: #4285f4;\n  color: #fff;\n  width: auto;\n  border: none;\n  padding: 1rem 0.75rem;\n  border-radius: 0.25rem;\n  cursor: pointer;\n  font-weight: bolder;\n  max-width: 200px;\n}\n\n.page2lockerForm label span {\n  margin-right: 5rem;\n  font-size: 1.45rem;\n  font-weight: bolder;\n  flex: 0.3;\n}\n\n.page2lockerForm button:hover {\n  background-color: #3367d6;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
