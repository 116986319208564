// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#all-connection-terms .button-group button{
    height: fit-content;
  padding: 0.5rem 1rem; /* Added padding for better click area */
  border: 1px solid #007bff; 
  border-radius: 0.25rem;
  background-color: #fff; /* White background for the button */
  color: #007bff; /* Button text color */
  cursor: pointer;
  font-weight: bold;
}

#all-connection-terms .button-group button:hover {
  background-color: #007bff; /* Button background color on hover */
  color: #fff; /* Button text color on hover */
  border: 1px solid #007bff; /* Keep border color on hover */
}`, "",{"version":3,"sources":["webpack://./src/components/ConnectionTypes/ConnectionTypes.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;EACrB,oBAAoB,EAAE,wCAAwC;EAC9D,yBAAyB;EACzB,sBAAsB;EACtB,sBAAsB,EAAE,oCAAoC;EAC5D,cAAc,EAAE,sBAAsB;EACtC,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,yBAAyB,EAAE,qCAAqC;EAChE,WAAW,EAAE,+BAA+B;EAC5C,yBAAyB,EAAE,+BAA+B;AAC5D","sourcesContent":["#all-connection-terms .button-group button{\n    height: fit-content;\n  padding: 0.5rem 1rem; /* Added padding for better click area */\n  border: 1px solid #007bff; \n  border-radius: 0.25rem;\n  background-color: #fff; /* White background for the button */\n  color: #007bff; /* Button text color */\n  cursor: pointer;\n  font-weight: bold;\n}\n\n#all-connection-terms .button-group button:hover {\n  background-color: #007bff; /* Button background color on hover */\n  color: #fff; /* Button text color on hover */\n  border: 1px solid #007bff; /* Keep border color on hover */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
